import './Social.css';

function Social() {
  return (
    <div>
      <a href="https://github.com/PatrickRoderman" target="_blank" rel="noreferrer">
        <i className="fa fa-github"></i>
      </a>
      <a href="http://www.linkedin.com/in/patrickroderman" target="_blank" rel="noreferrer"><i className="fa fa-linkedin"></i></a>
    </div>
  );
}

export default Social;
