import './App.css';
import Headline from './Headline';
import Pic from './Pic';
import Social from './Social';

function App() {
  return (
    <div className="App">
      <div id="card">
      <ul id="layout">
          <li>
            <ul id="side">
                <li>
                  <Pic/>
                </li>
                <li>
                  <Social/>
                </li>
        
            </ul>
          </li>
          <li>
            <Headline/>
          </li>
               </ul>
    </div>
    </div>
  );
}

export default App;
