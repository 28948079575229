import './Pic.css';
import headshot from './assets/img/headshot.jpg';
import GoDZILLA from './assets/img/GoDZILLA.png';
import PATcodes from './assets/img/PATcodes.jpg';

function Pic() {
  var pic = PATcodes
  if (!window.location.search) {
    pic = GoDZILLA
  }
  return (
      <div id="flippingContain">
        <div className="flip-container">
          <div className="flipper">
            <div className="front">
              <img id="profpic" src={headshot} alt="">
              </img>
            </div>
            <div className="back">
              <img src={pic} alt=""></img>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Pic;
