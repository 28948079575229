import './Headline.css';
import resume from './assets/Patrick_Roderman_Resume.pdf';
// import tcnj from "./assets/img/TCNJ Comp FULL B RGB.png";
import att from "./assets/img/att.png";
import fb from "./assets/img/fb.png";
import ck from "./assets/img/ck.png";
import ReactTooltip from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";
import React, { useState } from "react";

import * as IoIcons from "react-icons/io"

function Headline() {
  var fading = false
  function changeCopyText () {
      navigator.clipboard.writeText('mail@pat.codes')
      document.getElementsByClassName("__react_component_tooltip")[0].innerHTML = document.getElementsByClassName("__react_component_tooltip")[0].innerHTML.replace("Copy to Clipboard", "Copied!"); 
      var emailcopy = document.getElementsByClassName("email-copy")[0]
      emailcopy.style.opacity = ".5"
      setTimeout(function(){document.getElementsByClassName("__react_component_tooltip")[0].innerHTML = document.getElementsByClassName("__react_component_tooltip")[0].innerHTML.replace("Copied!","Copy to Clipboard");}, 3000);
      function fade(element) {
        var op = .5;  // initial opacity
        var timer = setInterval(function () {
            if (op >= .9){
                clearInterval(timer);
                element.style.opaciy = 1;
            }
            element.style.opacity = op;
            element.style.filter = 'alpha(opacity=' + op * 100 + ")";
            op += op * 0.1;
        }, 50);
        
    }
    function fadeMail() {
      fade(emailcopy)
    }
    if (fading === true) {
      return
    }
    fading = true
    setTimeout(fadeMail, 3000)
    fading = false 
  }
  function decideClick() {
    return setLgShow(true)
  }

  function GetLoader() {
    if (loaderShow) {
        return <div class="lds-heart"><div></div></div>
      }
  }

  function GetResumeButton() {
    if (window.innerWidth > 900) {
      return <li>
      <a className="resume" target="_blank" rel="noreferrer"  onClick={(e) => decideClick(e)}>resume&nbsp;<IoIcons.IoIosSearch className='searchicon'></IoIcons.IoIosSearch></a>
    </li>
    }


      return     <li>
      <a className="resume" target="_blank" rel="noreferrer"  href={resume}>resume&nbsp;<IoIcons.IoIosSearch className='searchicon'></IoIcons.IoIosSearch></a>
    </li>
  }

  const [loaderShow, setLoaderShow] = useState(false)
  const [lgShow, setLgShow] = useState(false)
  return (
    <div style={{overflow:"hidden"}}>
        <h1 id="name">PATRICK&nbsp; RODERMAN</h1><br/>

        <ul id="desc">
          <li className='subheading fadein select-none'><b>Software Engineering & Site Reliability</b></li>
          <li className='emailentry'>        
              <ReactTooltip id="toolTip" />
              <i id="tip" className="button email-copy" rel="noreferrer" data-delay-show='200' data-type='dark' data-offset="{'top': -7, 'left': -30}" data-effect='solid' data-tip="Copy to Clipboard" data-for='toolTip' data-place='top' onClick={() => {changeCopyText()}}><b>mail@pat.codes&nbsp;</b><i className="fa fa-copy"></i></i>  
          </li>
          <br/>
          <br/>
          <li className="history fadein">
          <hr className="fadein workHR" style={{margin:0}}/>
            <img className='work att'  src={att} style={{opacity:.3, height: 50}} alt=""></img>
            <img className='work fb' src={fb} style={{opacity:.5, height: 50}} alt=""></img>
            <img className='work ck'  src={ck} style={{opacity:.9, height: 43, paddingBottom: 5}} alt=""></img>
            <hr className="fadein workHR" style={{margin:0}}/>
          </li>
          {/* <li className='subheading fadein'><b>Engineering for reliable and secure systems at scale</b></li> */}
        </ul>
        
        <ul className="buttons">
        {GetResumeButton()}
        </ul>
        
        <Modal
          size="lg"
          show={lgShow}
          aria-labelledby="example-modal-sizes-title-lg modal shadow-sm"
          animation={true}
          onHide={() => setLgShow(false)}
          closeTimeoutMS={2000}
        >
        <ModalBody className="modalbody shadow-lg">
          <IoIcons.IoIosCloseCircleOutline
            className="closemodule"
            onClick={() => setLgShow(false)}
          />
          {GetLoader()}

  <Modal.Body className="resumebody">
  <object data={resume+"#toolbar=0&navpanes=0&scrollbar=0&zoom=82"} className="ResumePortal" type="application/pdf" style={{msOverflowStyle: 0, scrollbarWidth: 0, minHeight: "101%", overflow: "hidden"}}  onLoad={() => setLoaderShow(false)}>
        <embed src={resume + "#toolbar=0&navpanes=0&scrollbar=0&zoom=82"} style={{msOverflowStyle: 0, scrollbarWidth: 0}} type="application/pdf"  onLoad={() => setLoaderShow(false)}/>
    </object>
<a className="pdf" target="_blank" href={resume}><b>open  </b>&nbsp;<i className="fa fa-external-link"></i></a>
  </Modal.Body>
</ModalBody>
</Modal>
        
        

        </div>
  );
}

export default Headline;
